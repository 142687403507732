/** @format */

import React, { useEffect, useState } from "react";
import { createContext } from "react";
import Loadingmodel from "@models/Loadingmodel";
import { useDispatch, useSelector } from "react-redux";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import {
  add_remove_favorites,
  getEstimation_projectnames,
} from "@services/Userservices";
import Addrequiredservice from "@models/Addrequiredservice";
import Createestimationproject from "@models/Createestimationproject";
import { useLocation, useNavigate } from "react-router-dom";
import Customizedbutton from "../Customizedbutton";
import Sucessmodal from "@models/Sucessmodal";
import FullViewMedia from "@models/FullViewMedia";
import { Grid } from "@mui/material";
import { all_categoriesactions } from "@store/Allcategoriesslice";
import { usersubscription_check } from "@helpers/Helperfunctions";
import { isSubscriptionExpired } from "../../helpers/Helperfunctions";

const Loadingcontext = createContext();
export const Loadingprovider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const appVersion = (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
      gap={1}
    >
      {process.env.REACT_APP_ENV === "production-beta" ? (
        <Grid>Beta Versions</Grid>
      ) : null}
      <Grid>V (1.0.1)</Grid>
      <Grid>Api V (0.1)</Grid>
    </Grid>
  );
  const [loading, setLoading] = useState(false);
  const [prev_location, setPrev_location] = useState("");
  const [required_service, setRequired_service] = useState(false);
  const [project_model, setProject_model] = useState(false);
  const [requiredServiceFromBack, setRequiredServiceFromBack] = useState(false); // using this state, if required_service popup opened from the project_model popup or not
  const [slected_user, setSelected_user] = useState({});
  const [estimationtype, setEstimationtype] = useState(1);
  const [create_project_initialize, setCreate_project_initialize] =
    useState(false);
  const [chat_data, setChat_data] = useState({});
  const [sel_package, setSel_package] = useState({});
  const [adminheader, setAdminheader] = useState("Dashboard");
  const [successmodel_data, setSuccessmodel_data] = useState({
    open: false,
    message: {
      title: "Success!",
      html: (
        <>
          <p>You have successfully added this</p>
          <p>service to the Estimations List</p>
        </>
      ),
      button_text: (
        <>
          <Customizedbutton
            size={"xl"}
            onClick={() => {
              setSuccessmodel_data((prev) => ({ ...prev, open: false }));
              navigate("/dashboard/estimations");
            }}
            // styles={{ paddingLeft: "2rem", paddingRight: "2rem" }}
            data={"View Estimation List"}
          />
        </>
      ),
    },
  });
  const [fullViewMedia, setFullViewMedia] = useState({
    open: false,
    media: null,
    index: 0,
  });
  const profile_data = useSelector(
    (state) => state.complete_profile.profile_data
  );
  const user_data = useSelector((state) => state.authentication.data);
  /**Favourites api call */
  const addorremove_favourite = async (data) => {
    try {
      if (!profile_data?.userDetails?.profileStatus) {
        navigate("/mocalogin/profilecreation");
        return;
      }
      let check_subscription = await usersubscription_check(
        profile_data,
        user_data
      );
      if (!check_subscription) {
        return;
      }
      const plan_expired = await isSubscriptionExpired(
        user_data?.plan?.planEndDate
      );
      if (plan_expired) {
        customEnqueueSnackbar(
          "Your subscription is expired. Please Renew your plan.",
          {
            variant: "warning",
          }
        );
        return;
      }
      setLoading(true);
      const fav_req = {
        userId: user_data?.id,
        ...data,
      };
      const fav_res = await add_remove_favorites(fav_req);
      if (fav_res?.status) {
        setLoading(false);
        customEnqueueSnackbar(fav_res?.message, { variant: "success" });
        return true;
      } else {
        setLoading(false);
        customEnqueueSnackbar(fav_res?.message, { variant: "error" });
        return false;
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  /**On Clicking the new project in  Add to estimation member it is opening the create project model by getting the callback from addrequired service component*/
  const Projectmodel_opener = (type) => {
    // using type, to know where it is coming from if 1 setRequiredServiceFromBack true if not nothing
    if (type) {
      setRequiredServiceFromBack(true);
    }
    setCreate_project_initialize(true);
    setRequired_service(false);
    setProject_model(true);
  };

  /**
   * Get estimation names
   * @param {integer} pagenumber
   */
  const add_mem_estimation = async (member, type) => {
    try {
      const { userId: otheruserid } = member;
      let servicetype = estimationtype;
      let subcategoryid = member?.subCategoryId;
      let service_type =
        type === 1
          ? 1
          : subcategoryid === 9
          ? 2
          : subcategoryid === 10
          ? 3
          : subcategoryid === 11
          ? 4
          : subcategoryid === 12
          ? 5
          : subcategoryid === 17
          ? 6
          : subcategoryid === 7
          ? 7
          : 1;

      setLoading(true);
      let req = {
        userId: user_data?.id,
        min: 0,
        max: 10,
        serviceUserId: otheruserid,
        serviceType: service_type,
        serviceId: 0,
      };
      if (servicetype === 2) {
        req.serviceId = slected_user?.id;
        req.serviceType = service_type;
      }
      const estimation_list_res = await getEstimation_projectnames(req);
      if (estimation_list_res?.status) {
        if (estimation_list_res?.data?.count === 0) {
          Projectmodel_opener();
        } else {
          setRequired_service(true);
        }
        setLoading(false);
      } else {
        setLoading(true);
        customEnqueueSnackbar(estimation_list_res?.message, {
          variant: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Handling for preview of images or videos
   * @param {object} data
   * @param {integer} mediaIndex
   */
  const handleFullViewMedia = (data, mediaIndex) => {
    setFullViewMedia((prev) => ({
      ...prev,
      open: true,
      media: data,
      index: mediaIndex,
    }));
  };
  /**setting the chatdata to empty object when it is leaving that messages path */
  useEffect(() => {
    if (
      location?.pathname !== "/dashboard/messages" &&
      prev_location === "/dashboard/messages"
    ) {
      setChat_data({});
    } else if (
      [
        "/home",
        "/dashboard",
        "/findwork",
        "/castingcall",
        "/contactus",
        "/postrequest",
      ].includes(location?.pathname)
    ) {
      dispatch(all_categoriesactions.setSearchstr(""));
    }
    setPrev_location(location?.pathname);
  }, [location?.pathname]);
  return (
    <>
      {/**Here Addrequiredservice and create estimation is rendering here only
       * so opening and closing of models can be controlled by using usecontext
       * this is used by to addmember to estimation list where this models are
       * used in multiple components like category_profiles,categoryprofile_locations,
       * Albumthumbnail(mess),Equipmentdetailscard components These models only use throughout
       *  the app for estimations*/}
      {required_service && (
        <Addrequiredservice
          open={required_service}
          close={(data) => {
            setSel_package({});
            /** if data -> 1 then member is added to estimation list succesfuly and need to open the success popup*/
            if (data === 1) {
              setSuccessmodel_data((prev) => ({ ...prev, open: true }));
            }
            setRequired_service(false);
          }}
          sel_user={slected_user}
          servicetype={estimationtype}
          selected_package={sel_package}
          openingtype={create_project_initialize}
          Project_creation={Projectmodel_opener}
        />
      )}
      {project_model && (
        <Createestimationproject
          open={project_model}
          close={(isrequired) => {
            if (isrequired === 1 || requiredServiceFromBack) {
              setRequired_service(true);
              setRequiredServiceFromBack(false);
            }
            setProject_model(false);
          }}
        />
      )}
      {/**After member has added to estimation list it shows the success popup and we can also redirect to the estimations screen by clickin on view estimations button */}
      {successmodel_data?.open && (
        <Sucessmodal
          open={successmodel_data?.open}
          close={() =>
            setSuccessmodel_data((prev) => ({ ...prev, open: false }))
          }
          message={successmodel_data?.message}
        />
      )}
      {/* Images and videos preview model implementation */}
      {fullViewMedia?.open && (
        <FullViewMedia
          open={fullViewMedia?.open}
          close={() => setFullViewMedia((prev) => ({ ...prev, open: false }))}
          data={fullViewMedia?.media}
          selectedIndex={fullViewMedia?.index}
        />
      )}
      {/**This loading model is rendered throught the app without importing in all components
       * by rendering the loading model in context we can controll the opening nad closing through the context api
       */}
      {loading && <Loadingmodel open={loading} />}{" "}
      <Loadingcontext.Provider
        value={{
          loading,
          setLoading,
          addorremove_favourite,
          adminheader,
          setAdminheader,
          setSelected_user,
          setSel_package,
          setEstimationtype,
          add_mem_estimation,
          chat_data,
          setChat_data,
          handleFullViewMedia,
          appVersion,
          isFullViewMedia: fullViewMedia?.open,
        }}
      >
        {children}
      </Loadingcontext.Provider>
    </>
  );
};
export default Loadingcontext;
